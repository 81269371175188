













import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import HomeLoanCalculator from '@/components/Calculators/HomeLoanCalculator.vue';

@Component({
  components: {
    HomeLoanCalculator,
  },
})
export default class Calculators extends Mixins(View) {
}
